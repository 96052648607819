<template>
<div>
  <date-picker>
    {{ checkpoint.handler_payload.label }}
  </date-picker>    
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  computed: mapGetters('checkpoint', [
    'checkpoint'
  ])

}
</script>